import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "server-side-rendering"
    }}><a parentName="h1" {...{
        "href": "#server-side-rendering",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Server Side Rendering`}</h1>
    <h2 {...{
      "id": "install"
    }}><a parentName="h2" {...{
        "href": "#install",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Install`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @loadable/server && npm install --save-dev @loadable/babel-plugin @loadable/webpack-plugin
# or using yarn
yarn add @loadable/server && yarn add --dev @loadable/babel-plugin @loadable/webpack-plugin
`}</code></pre>
    <h2 {...{
      "id": "guide"
    }}><a parentName="h2" {...{
        "href": "#guide",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Guide`}</h2>
    <h3 {...{
      "id": "1-install-loadablebabel-plugin"
    }}><a parentName="h3" {...{
        "href": "#1-install-loadablebabel-plugin",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`1. Install `}<inlineCode parentName="h3">{`@loadable/babel-plugin`}</inlineCode></h3>
    <p><strong parentName="p">{`.babelrc`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "plugins": ["@loadable/babel-plugin"]
}
`}</code></pre>
    <h3 {...{
      "id": "2-install-loadablewebpack-plugin"
    }}><a parentName="h3" {...{
        "href": "#2-install-loadablewebpack-plugin",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`2. Install `}<inlineCode parentName="h3">{`@loadable/webpack-plugin`}</inlineCode></h3>
    <p><strong parentName="p">{`webpack.config.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const LoadablePlugin = require('@loadable/webpack-plugin')

module.exports = {
  // ...
  plugins: [new LoadablePlugin()],
}
`}</code></pre>
    <h3 {...{
      "id": "3-setup-chunkextractor-server-side"
    }}><a parentName="h3" {...{
        "href": "#3-setup-chunkextractor-server-side",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`3. Setup `}<inlineCode parentName="h3">{`ChunkExtractor`}</inlineCode>{` server-side`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ChunkExtractor } from '@loadable/server'

// This is the stats file generated by webpack loadable plugin
const statsFile = path.resolve('../dist/loadable-stats.json')

// We create an extractor from the statsFile
const extractor = new ChunkExtractor({ statsFile })

// Wrap your application using "collectChunks"
const jsx = extractor.collectChunks(<YourApp />)

// Render your application
const html = renderToString(jsx)

// You can now collect your script tags
const scriptTags = extractor.getScriptTags() // or extractor.getScriptElements();

// You can also collect your "preload/prefetch" links
const linkTags = extractor.getLinkTags() // or extractor.getLinkElements();

// And you can even collect your style tags (if you use "mini-css-extract-plugin")
const styleTags = extractor.getStyleTags() // or extractor.getStyleElements();
`}</code></pre>
    <h3 {...{
      "id": "4-add-loadableready-client-side"
    }}><a parentName="h3" {...{
        "href": "#4-add-loadableready-client-side",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`4. Add `}<inlineCode parentName="h3">{`loadableReady`}</inlineCode>{` client-side`}</h3>
    <p>{`Loadable components loads all your scripts asynchronously to ensure optimal performances. All scripts are loaded in parallel, so you have to wait for them to be ready using `}<inlineCode parentName="p">{`loadableReady`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { loadableReady } from '@loadable/component'

loadableReady(() => {
  const root = document.getElementById('main')
  hydrate(<App />, root)
})
`}</code></pre>
    <p><strong parentName="p">{`🚀 `}<a parentName="strong" {...{
          "href": "https://github.com/gregberge/loadable-components/tree/master/examples/server-side-rendering"
        }}>{`Checkout the complete example in this repository`}</a></strong></p>
    <h2 {...{
      "id": "collecting-chunks"
    }}><a parentName="h2" {...{
        "href": "#collecting-chunks",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Collecting chunks`}</h2>
    <p>{`The basic API goes as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderToString } from 'react-dom/server'
import { ChunkExtractor } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')
const extractor = new ChunkExtractor({ statsFile })
const html = renderToString(extractor.collectChunks(<YourApp />))
const scriptTags = extractor.getScriptTags() // or extractor.getScriptElements();
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`collectChunks`}</inlineCode>{` method wraps your element in a provider. Optionally you can use the `}<inlineCode parentName="p">{`ChunkExtractorManager`}</inlineCode>{` provider directly, instead of this method. Just make sure not to use it on the client-side.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderToString } from 'react-dom/server'
import { ChunkExtractor, ChunkExtractorManager } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')
const extractor = new ChunkExtractor({ statsFile })
const html = renderToString(
  <ChunkExtractorManager extractor={extractor}>
    <YourApp />
  </ChunkExtractorManager>,
)

const scriptTags = extractor.getScriptTags() // or extractor.getScriptElements();
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`extractor.getScriptTags()`}</inlineCode>{` returns a string of multiple `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tags marked as "async". You have to wait for them to be ready using `}<inlineCode parentName="p">{`loadableReady`}</inlineCode>{`.`}</p>
    <p>{`Alternatively the `}<inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{` also has a `}<inlineCode parentName="p">{`getScriptElements()`}</inlineCode>{` method that returns an array of React elements.`}</p>
    <h2 {...{
      "id": "streaming-rendering"
    }}><a parentName="h2" {...{
        "href": "#streaming-rendering",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Streaming rendering`}</h2>
    <p>{`Loadable is compatible with streaming rendering, if you use it you have to include script when the stream is complete.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderToNodeStream } from 'react-dom/server'
import { ChunkExtractor } from '@loadable/server'

// if you're using express.js, you'd have access to the response object "res"

// typically you'd want to write some preliminary HTML, since React doesn't handle this
res.write('<html><head><title>Test</title></head><body>')

const statsFile = path.resolve('../dist/loadable-stats.json')
const chunkExtractor = new ChunkExtractor({ statsFile })
const jsx = chunkExtractor.collectChunks(<YourApp />)
const stream = renderToNodeStream(jsx)

// you'd then pipe the stream into the response object until it's done
stream.pipe(res, { end: false })

// and finalize the response with closing HTML
stream.on('end', () =>
  res.end(\`\${chunkExtractor.getScriptTags()}</body></html>\`),
)
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Streaming rendering is not compatible with prefetch `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` tags.`}</p>
    </blockquote>
    <h2 {...{
      "id": "prefetching"
    }}><a parentName="h2" {...{
        "href": "#prefetching",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Prefetching`}</h2>
    <p><a parentName="p" {...{
        "href": "https://webpack.js.org/guides/code-splitting/#prefetching-preloading-modules"
      }}>{`Webpack prefetching`}</a>{` is supported out of the box by Loadable. `}<a parentName="p" {...{
        "href": "https://css-tricks.com/prefetching-preloading-prebrowsing/"
      }}><inlineCode parentName="a">{`<link rel="preload">`}</inlineCode>{` and `}<inlineCode parentName="a">{`<link rel="prefetch">`}</inlineCode></a>{` can be added directly server-side to improve performances.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import path from 'path'
import { ChunkExtractor, ChunkExtractorManager } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')
const extractor = new ChunkExtractor({ statsFile })

const jsx = extractor.collectChunks(<YourApp />)

const html = renderToString(jsx)

const linkTags = extractor.getLinkTags() // or chunkExtractor.getLinkElements();

const html = \`<html>
  <head>\${linkTags}</head>
  <body>
    <div id="root">\${html}</div>
  </body>
</html>\`
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`It only works with `}<inlineCode parentName="p">{`renderToString`}</inlineCode>{` API. Since `}<inlineCode parentName="p">{`<link>`}</inlineCode>{` must be added in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{`, you can't do it using `}<inlineCode parentName="p">{`renderToNodeStream`}</inlineCode>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "css"
    }}><a parentName="h2" {...{
        "href": "#css",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`CSS`}</h2>
    <p>{`Extracted CSS using plugins like `}<a parentName="p" {...{
        "href": "https://github.com/webpack-contrib/mini-css-extract-plugin"
      }}>{`"mini-css-extract-plugin"`}</a>{` are automatically collected, you can get them using `}<inlineCode parentName="p">{`getStyleTags`}</inlineCode>{` or `}<inlineCode parentName="p">{`getStyleElements`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderToString } from 'react-dom/server'
import { ChunkExtractor } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')
const extractor = new ChunkExtractor({ statsFile })
const html = renderToString(extractor.collectChunks(<YourApp />))
const styleTags = extractor.getStyleTags() // or extractor.getStyleElements();
`}</code></pre>
    <h2 {...{
      "id": "disable-ssr-on-a-specific-loadable"
    }}><a parentName="h2" {...{
        "href": "#disable-ssr-on-a-specific-loadable",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Disable SSR on a specific loadable`}</h2>
    <p>{`Disable SSR on a specific loadable component with `}<inlineCode parentName="p">{`ssr: false`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

// This dynamic import will not be processed server-side
const Other = loadable(() => import('./Other'), { ssr: false })
`}</code></pre>
    <h2 {...{
      "id": "override-statspublicpath-at-runtime"
    }}><a parentName="h2" {...{
        "href": "#override-statspublicpath-at-runtime",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Override `}<inlineCode parentName="h2">{`stats.publicPath`}</inlineCode>{` at runtime`}</h2>
    <p>{`To override `}<inlineCode parentName="p">{`stats.publicPath`}</inlineCode>{` at runtime, pass in a custom `}<inlineCode parentName="p">{`publicPath`}</inlineCode>{` to the `}<inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{` constructor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ChunkExtractor } from '@loadable/server'

const statsFile = path.resolve('../dist/loadable-stats.json')

const extractor = new ChunkExtractor({
  statsFile,
  publicPath: 'https://cdn.example.org/v1.1.0/',
})
`}</code></pre>
    <h2 {...{
      "id": "chunkextractor-entrypoints"
    }}><a parentName="h2" {...{
        "href": "#chunkextractor-entrypoints",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a><inlineCode parentName="h2">{`ChunkExtractor`}</inlineCode>{` entrypoints`}</h2>
    <p>{`When running your build, notice `}<inlineCode parentName="p">{`@loadable/webpack-plugin`}</inlineCode>{` generates a file called `}<inlineCode parentName="p">{`loadable-stats.json`}</inlineCode>{`, which contains information
about all your entries and chuncks from webpack.`}</p>
    <p>{`Once that's in place, `}<inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{` will be responsible of finding your entries into this file.`}</p>
    <p>{`The default behaviour of webpack, is to create an asset called `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` if no named entry is specified, like so.`}</p>
    <p><strong parentName="p">{`webpack.config.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  entry: './src/index.js',
  // ...
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://webpack.js.org/configuration/entry-context/#naming"
      }}>{`Checkout webpack's entry naming configuration`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`ChunkExtractor`}</inlineCode>{` will try to find your `}<inlineCode parentName="p">{`main.js`}</inlineCode>{`, and will look into `}<inlineCode parentName="p">{`loadable-stats.json`}</inlineCode>{` to confirm it's there.`}</p>
    </blockquote>
    <p>{`If for instance, your wish is to get a different named entry, you will need to pass an `}<inlineCode parentName="p">{`entrypoints`}</inlineCode>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const extractor = new ChunkExtractor({
  statsFile,
  entrypoints: ['client'], // array of webpack entries (default: ['main'])
})
`}</code></pre>
    <h2 {...{
      "id": "using-your-own-stats-file"
    }}><a parentName="h2" {...{
        "href": "#using-your-own-stats-file",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Using your own stats file`}</h2>
    <p>{`By default, the webpack plugin adds an asset to the webpack build called `}<inlineCode parentName="p">{`loadable-stats.json`}</inlineCode>{`. This contains the result of running webpack's `}<a parentName="p" {...{
        "href": "https://webpack.js.org/api/node/#statstojsonoptions"
      }}><inlineCode parentName="a">{`stats.toJson()`}</inlineCode></a>{` with the following options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  hash: true,
  publicPath: true,
  assets: true,
  chunks: false,
  modules: false,
  source: false,
  errorDetails: false,
  timings: false,
}
`}</code></pre>
    <p><inlineCode parentName="p">{`stats.toJson()`}</inlineCode>{` is an expensive operation, and it can significantly slow down webpack watching recompiles.
If you already have a webpack stats file in your build that includes the necessary options, you may choose to use your existing stats object instead of creating a new one. You can do this as follows:`}</p>
    <ul>
      <li parentName="ul">{`pass your existing stats object into `}<a parentName="li" {...{
          "href": "/docs/api-loadable-server/#chunkextractor"
        }}><inlineCode parentName="a">{`ChunkExtractor`}</inlineCode></a>{` via the `}<inlineCode parentName="li">{`stats`}</inlineCode>{` option`}</li>
      <li parentName="ul">{`disable both the `}<inlineCode parentName="li">{`outputAsset`}</inlineCode>{` and `}<inlineCode parentName="li">{`writeToDisk`}</inlineCode>{` options in the `}<a parentName="li" {...{
          "href": "/docs/api-loadable-webpack-plugin/#loadableplugin"
        }}>{`webpack plugin`}</a>{` to prevent it from calling `}<inlineCode parentName="li">{`stats.toJson()`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      